/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FormHandles, Scope } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'
import _cloneDeep from 'lodash/cloneDeep'

import { FiCheck, FiX } from 'react-icons/fi'
import { Details } from '@material-ui/icons'
import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'

import Header from '../../components/Header'
import Button from '../../components/Button'

import {
  Container,
  Content,
  InfoBox,
  ContentBenef,
  BenefBox,
  Line,
} from './styles'
import { UserData, Participant } from '../../utils/interfaces'

interface CopiaDependent {
  data: {
    name: string
    cpf: string
    birthdate: string
  }
  details: {
    tipVinculo: string
    tipoBen: string
    grauParentesco: string
    dcrGrauParentesco: string
    proporcao: number
  }
}

const ParticipantsList: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [partLength, setPartLength] = useState(participants.length)

  const arrBnfInd = participants.filter(p => p.details.tipoBen === '1')
  // const arrBnfLeg = participants.filter(p => p.details.tipoBen === '2')
  // const soma = arrBnfInd.reduce((t, p) => t + p.details.proporcao, 0)

  // const [partIndLength, setPartIndLength] = useState(arrBnfInd.length)
  // // console.log(arrBnfInd, arrBnfLeg, soma)

  // const [totalProporcao, setTotalProporcao] = useState(soma)

  const dependentsListCopy: CopiaDependent[] = _cloneDeep(participants)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const handleAddNovo = useCallback(() => {
    history.push('/new-participant')
  }, [history])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
      setPartLength(participants.length)
    },
    [participants, setParticipants],
  )

  useEffect(() => {
    setPartLength(participants.length)
  }, [setParticipants, participants, handleRemove])

  const handleSubmit = useCallback(() => {
    history.push('/resume')
  }, [history])

  // const teste = useCallback(
  //   async arrAux => {
  //     await setParticipants([...arrAux])
  //     setTotalProporcao(100)
  //   },
  //   [setParticipants],
  // )

  // const handleProporcionalizar = useCallback(async () => {
  //   const value = (100 / partIndLength).toFixed(2)

  //   const contador = partLength

  //   for (let i = 0; i < contador; i += 1) {
  //     if (dependentsListCopy[i].details.tipoBen === '1') {
  //       dependentsListCopy[i].details.proporcao = parseFloat(value)
  //     }
  //   }
  //   teste(dependentsListCopy)
  // }, [partIndLength, partLength, teste, dependentsListCopy])

  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <InfoBox>
            <h3>Titular</h3>
            <div>
              <strong>Nome: </strong>
              <input value={userData.name} disabled />
            </div>
            <div>
              <strong>Telefone: </strong>
              <input value={userData.phone} disabled />
            </div>
            <div>
              <strong>Idade: </strong>
              <input value={calculaIdade(userData.birthdate)} disabled />
            </div>
          </InfoBox>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {partLength > 0 ? (
              <BenefBox>
                <h3>Beneficiários</h3>
                {dependentsListCopy.map((participant, idx) => (
                  <ContentBenef key={Math.random()}>
                    <Scope path={`parts[${idx}]`}>
                      <FiX onClick={() => handleRemove(idx)} />
                      <div>
                        <small>
                          {`${idx + 1} - Beneficiário ${
                            participant.details.tipoBen === '2'
                              ? ' Legal'
                              : participant.details.tipoBen === '1'
                              ? 'Indicado'
                              : 'Legal/Indicado'
                          }`}
                        </small>
                      </div>
                      <div>
                        <strong>Nome: </strong>
                        <input value={participant.data.name} disabled />
                      </div>
                      <div>
                        <strong>Parentesco: </strong>
                        <input
                          value={participant.details.dcrGrauParentesco}
                          disabled
                        />
                      </div>
                      <div>
                        <strong>Idade: </strong>
                        <input
                          value={`${calculaIdade(
                            participant.data.birthdate,
                          )} anos`}
                          disabled
                        />
                      </div>
                      {participant.details.tipoBen === '2' ? (
                        <></>
                      ) : (
                        <div>
                          <strong>Proporção: </strong>
                          <input
                            value={`${participant.details.proporcao} %`}
                            disabled
                          />
                        </div>
                      )}
                    </Scope>
                    {partLength > 1 ? <Line /> : <></>}
                  </ContentBenef>
                ))}
              </BenefBox>
            ) : (
              <></>
            )}
          </Form>
        </Content>

        {/* {totalProporcao < 100 && partIndLength > 0 ? (
          <BenefBox>
            <p>
              Você ainda não distribuiu <b>100% da proporção</b> entre seus{' '}
              <b>beneficiários indicados</b>. Adicione mais beneficiários ou{' '}
              <span onClick={() => handleProporcionalizar()}>clique aqui</span>{' '}
              para distribuir igualmente entre todos os informados.
            </p>
          </BenefBox>
        ) : ( */}
        <Button
          type="button"
          fontSize="normal"
          width="medium"
          onClick={handleAddNovo}
          color="darkGreen"
        >
          {participants.length > 0
            ? 'Adicionar mais um beneficiário'
            : 'Adicionar um beneficiário'}
        </Button>
        <Button
          type="button"
          fontSize="normal"
          color="blue"
          width="medium"
          onClick={handleClickSubmit}
        >
          {partLength === 0 ? (
            <span>Não possuo beneficiários</span>
          ) : (
            <>
              <FiCheck size={45} />
              <span>Pronto! Avançar</span>
            </>
          )}
        </Button>
        {/* )} */}
      </Container>
    </>
  )
}

export default ParticipantsList
