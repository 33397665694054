import { transparentize } from 'polished';
import styled from 'styled-components'

interface BtnContatoProps {
  isActive: boolean;
}
export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin-bottom: 10px;

  > strong {
    text-align: center;
    font-size: 18px;
    color: #23378D;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  form {
    width: 100%;
  }

  > p {
    text-align: center;
    margin-bottom: 15px;
  }

  h4 {
    color: #23378D;
    width: 100%;
    text-align: center;
    margin: 0 0 8px 0;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 30px auto 15px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin: 12px 0 0;

    strong {
      font-size: 16px;
      color: ${props => props.theme.colors.primary}
    }

    small {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 600px) {
    > div {
      p {
        width: 70%;
      }
    }
  }
`

export const PercentualBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    border: 1px solid #D2D2D2;
    border-radius: 4px;
    height: 36px;
    max-width: 110px;
    display: inline-flex;
    justify-content: center;
    padding: 8px;

    input {
      text-align: center;
      border: none;
      width: 60%;
      &:disabled {
        background: transparent;
      }
    }
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    > div {
      margin-top: 5px;
    }
  }
`

interface BtnProps {
  isSelected?: boolean
}
export const ButtonSimulationCalc = styled.button<BtnProps>`
  width: 35px;
  height: 35px;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid ${props => props.isSelected ? '#23378D' : '#c1c1c1'};
  background-color: ${props => props.isSelected ? 'rgba(11, 45, 132,0.2)' : '#F4F4F9'};
`

export const ValueSimulation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  padding: 4px;

`

export const ButtonController = styled.button<BtnProps>`
  border-radius: 50%;
  border: none;
  background-color: transparent;
  width: 20px;

  > svg {
  color: ${props => props.isSelected ? '#23378D' : '#D2D2D2'};
  }
  `

export const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:relative;

  width: 100%;
  padding: 16px 12px;
  margin-bottom: 12px;

  background: initial;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.4, '#3E3E3E')};
  color: ${props => props.theme.colors.placeholder};
  transition: .2s;

  > label {
    transform: translateY(-12px);
    font-size: 12px;
    font-weight: bold;
  }

  > div {
    width: 70%;
    margin: 0 auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    color: '#23378D';
    cursor: pointer;
    transform: scale(1);
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 766px) {
    > div {
      width: 100%;
    }
  }
`

export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background: ${props => props.isActive? 'linear-gradient(15deg, #23378D, #4050FE)' : 'transparent'} ;
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder};

  transition: all .2s ease-in-out;

  &:focus {
    text-decoration: underline;
  }
`
