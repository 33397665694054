/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { FiCheck, FiCheckCircle } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import {
  addYears,
  differenceInMonths,
  differenceInYears,
  parseISO,
} from 'date-fns'
import { Base64 } from 'js-base64'
import {
  Container,
  Content,
  InfoValuesBox,
  GraphWrapper,
  BoxButtons,
  ColoredBoxInfo,
  ReceiveBox,
  ButtonSelectBox,
  ButtonSimulationCalc,
  BtnVoltar,
  ButtonSimulationValue,
  Line,
} from './styles'
import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'
import Button from '../../components/Button'
import { UserData, UserDetails, ConfigData } from '../../utils/interfaces'
import Header from '../../components/Header'
import { Valor, ValorMascarar } from '../../utils/masks'
import calculaIdade from '../../utils/calculaIdade'
import simulationYearsInfo from '../../assets/simulation-years-info.svg'
import simulationPercentageInfo from '../../assets/simulation-percentage-info.svg'
import simulationSelectTimeIcon from '../../assets/simulation-select-time-icon.svg'
import simulationSelectPercentIcon from '../../assets/simulation-select-percent-icon.svg'
import InputHidden from '../../components/InputHidden'
import api from '../../services/api'

interface CustomTooltipInterface {
  active: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
  label: string
}

interface Values {
  year: number
  invested: number
  finalBalance: number
  rentability: number
}

const Simulation: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [totalBalance, setTotalBalance] = usePersistedState('totalBalance', 0)

  const [flagEdit] = usePersistedState<'S' | 'N'>('flagEdit', 'N')
  const [percentualValuePercent, setPercentualValuePercent] = usePersistedState<
    number
  >('PercentualValuePercent', 0.3)
  const [totalInvested, setTotalInvested] = useState(0)
  const [values, setValues] = useState<Values[]>([])
  const [launched, setLaunched] = useState(false)
  const [selectedReceive, setSelectedReceive] = usePersistedState<
    'PD' | 'PS' | 'VF' | 'RV' | ''
  >('receiveTypeSelected', 'PS')
  const [displayPercent, setDisplayPercent] = useState(selectedReceive === 'PS')

  const [displayVitalicio, setDisplayVitalicio] = useState(
    selectedReceive === 'RV',
  )

  const history = useHistory()

  const ctbParticipante = userDetails.contribuicaoBasica

  const ctbPatrocinadora = userDetails.contribuicaoPatrocinadora

  const idadeAtual = calculaIdade(userData.birthdate)
  const actualYear = new Date().getFullYear()
  const lastYear = actualYear + userDetails.years

  const percentualValue = useMemo(
    () => formatValue(totalBalance * (percentualValuePercent / 100)),
    [percentualValuePercent, totalBalance],
  )
  const [vlrRendaVitalicia, setVlrRendaVitalicia] = useState(0)
  const [fatorAtuarial, setFatorAtuarial] = useState(0)

  const formRef = useRef<FormHandles>(null)

  const buscaRendaVitalicia = useCallback(
    async totalBlc => {
      const parametros = Base64.encode(
        `{"cliente":"${configData.codCliente}",
        "nascimento":"${userData.birthdate}",
        "sexoParticipante": "${userData.sex}",
        "estadoCivil":"${userData.maritalStatus}",
        "mrcInvalidez":"${userData.invalid}",
        "nascimentoConj":"${
          userData.birthdateMarital === undefined
            ? ''
            : userData.birthdateMarital
        }",
        "sexoConj":"${
          userData.sexMarital === undefined ? '' : userData.sexMarital
        }",
        "saldoProjet":"${totalBlc.toFixed(2).replace('.', ',')}",
        "idadeApos":"${userDetails.age}"
      }`,
      )

      const parametrosFinal = Base64.encode(parametros)

      await api
        .get(`wsRendaVitalicia.rule?sys=ADZ&Entrada=${parametrosFinal}`)
        .then(res => {
          setVlrRendaVitalicia(res.data.ValorMensal)
          setFatorAtuarial(res.data.FatorAtuarial)
        })
        .catch(res => {
          setVlrRendaVitalicia(0)
        })
    },
    [configData, userData, userDetails],
  )

  // const createValues = useCallback(() => {
  //   const valuesCalculated: Values[] = []
  //   let totalInvestedTemp = totalInvested
  //   let idade = idadeAtual
  //   let temporaryFinalBalance = 0

  //   for (let i = actualYear + 1; i <= lastYear; i += 1) {
  //     // const rentability = 0.2676901 // 3.26% aa.
  //     const rentability = (1 + 5) ** (1 / 12 - 1)

  //     let finalBalance = 0

  //     for (let j = 1; j <= 13; j += 1) {
  //       const monthinvested =
  //         userDetails.contribuicaoBasica + userDetails.contribuicaoPatrocinadora

  //       totalInvestedTemp += monthinvested

  //       const monthinvestedDesc = ctbParticipante + ctbPatrocinadora

  //       finalBalance =
  //         temporaryFinalBalance * (rentability / 100) +
  //         (monthinvestedDesc + temporaryFinalBalance)

  //       temporaryFinalBalance = finalBalance
  //     }

  //     const val: Values = {
  //       year: i,
  //       invested: totalInvestedTemp,
  //       rentability,
  //       finalBalance,
  //     }
  //     idade += 1
  //     valuesCalculated.push(val)
  //   }
  //   setTotalInvested(totalInvestedTemp)
  //   setTotalBalance(temporaryFinalBalance)
  //   setValues(valuesCalculated)
  //   setLaunched(true)
  // }, [
  //   actualYear,
  //   ctbParticipante,
  //   ctbPatrocinadora,
  //   idadeAtual,
  //   lastYear,
  //   setTotalBalance,
  //   totalInvested,
  //   userDetails,
  // ])

  const createValues = useCallback(() => {
    const valuesCalculated: Values[] = []
    let totalInvestedTemp = totalInvested
    let temporaryFinalBalance = 0

    let contBasicaPartic = userDetails.contribuicaoBasica
    let contPatroc = userDetails.contribuicaoPatrocinadora

    const dataAposentadoria = addYears(
      parseISO(userData.birthdate),
      userDetails.age,
    )

    const monthsContrib = differenceInMonths(dataAposentadoria, new Date())
    const yearsContrib = differenceInYears(dataAposentadoria, new Date())
    const totalContribution = monthsContrib + yearsContrib
    const rentability = (1 + 5) ** (1 / 12 - 1) // 5% ao ano.
    let finalBalance = 0
    let counter = 1

    for (counter; counter <= totalContribution + 2; counter += 1) {
      const monthinvested = contBasicaPartic + contPatroc

      totalInvestedTemp += monthinvested

      finalBalance =
        temporaryFinalBalance * (rentability / 100) +
        (monthinvested + temporaryFinalBalance)

      temporaryFinalBalance = finalBalance

      /* EVOLUÇÃO SALARIAL */
      if (counter % 13 === 0) {
        contBasicaPartic += contBasicaPartic * 0.03
        contPatroc += contPatroc * 0.03
      }

      const val: Values = {
        // eslint-disable-next-line no-bitwise
        year: new Date().getFullYear() + ~~(counter / 12),
        invested: totalInvestedTemp,
        rentability,
        finalBalance,
      }
      valuesCalculated.push(val)
    }

    setTotalInvested(totalInvestedTemp)
    setTotalBalance(temporaryFinalBalance)
    // setTotalInvestedPartic(tempTotalInvestedPartic)
    // setTotalInvestedPatroc(tempTotalInvestedPatroc)
    setValues(valuesCalculated)
    setLaunched(true)
  }, [totalInvested, userDetails, userData, setTotalBalance])

  useEffect(() => {
    if (!launched) {
      createValues()
    }
    buscaRendaVitalicia(totalBalance)
  }, [buscaRendaVitalicia, createValues, launched, totalBalance])

  const graphValues = values.map(value => ({
    year: value.year,
    value: value.finalBalance,
    formattedValue: Valor(value.finalBalance.toFixed(2)),
  }))

  const CustomTooltip = useCallback(
    ({ active, payload, label }: CustomTooltipInterface) => {
      if (active) {
        return (
          <div className="tooltip">
            <h4>Ano: {label}</h4>
            <p>
              Saldo: <span>{Valor(payload[0].value.toFixed(2))}</span>
            </p>
          </div>
        )
      }
      return null
    },
    [],
  )

  const toggleSelectedReceive = useCallback(
    (selectedReceiveNow: 'PD' | 'PS' | 'VF' | 'RV' | '') => {
      setSelectedReceive(selectedReceiveNow)
      if (selectedReceiveNow === 'PS') {
        setDisplayPercent(true)
        setDisplayVitalicio(false)
      } else if (selectedReceiveNow === 'RV') {
        setDisplayPercent(false)
        setDisplayVitalicio(true)
      } else {
        setDisplayPercent(false)
        setDisplayVitalicio(false)
      }
    },
    [setSelectedReceive],
  )

  const adicPerct = useCallback(() => {
    if (percentualValuePercent >= 2) {
      setPercentualValuePercent(2)
    } else {
      setPercentualValuePercent(percentualValuePercent + 0.1)
    }
  }, [setPercentualValuePercent, percentualValuePercent])

  const tiraPerct = useCallback(() => {
    if (percentualValuePercent <= 0.3) {
      setPercentualValuePercent(0.3)
    } else {
      setPercentualValuePercent(percentualValuePercent - 0.1)
    }
  }, [setPercentualValuePercent, percentualValuePercent])

  const handleSubmit = useCallback(() => {
    if (flagEdit === 'S') {
      history.push('/resume')
    } else {
      history.push('/register')
    }
  }, [flagEdit, history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="values-box">
            <InfoValuesBox color="pink" gradientDirection="right">
              <span>Valor Investido</span>
              <h3>{formatValue(totalInvested)}</h3>
              <small>Seu investimento no período</small>
            </InfoValuesBox>

            <InfoValuesBox color="green" gradientDirection="left">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>Seu investimento + rentabilidade</small>
            </InfoValuesBox>
          </div>
          <article>
            <p>
              Os dados não mentem. <br />
              Quer ver? Arraste o mouse no gráfico e descubra a evolução do seu
              patrimônio no decorrer dos próximos anos.
            </p>
          </article>

          <GraphWrapper>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={graphValues}>
                <defs>
                  <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#F07D00" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#F07D00" stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#F07D00"
                  fillOpacity={1}
                  fill="url(#color)"
                />

                <Tooltip
                  content={
                    <CustomTooltip active={false} payload={2} label="" />
                  }
                />
                <XAxis name="Ano" dataKey="year" />
                <YAxis
                  name="Saldo"
                  dataKey="value"
                  width={75}
                  axisLine={false}
                  tickLine={false}
                  tickCount={5}
                  tickFormatter={number => `${Valor(number.toFixed(2))}`}
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  opacity={0.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphWrapper>
          <small>
            Os valores são simulações e não há garantia de rentabilidade futura.
            Para a projeção acima foi utilizado o percentual de 5% ao ano.
          </small>
        </Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <strong>
              Escolha a forma de recebimento mensal <br /> do seu benefício:
            </strong>
            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PS')}
                selected={selectedReceive === 'PS'}
              >
                <img src={simulationSelectPercentIcon} alt="Percentual" />
                <span>Percentual sobre o saldo</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayPercent}>
                <ButtonSimulationCalc type="button" onClick={() => tiraPerct()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationValue type="button">
                  <span>{parseFloat(percentualValuePercent.toFixed(2))}%</span>
                </ButtonSimulationValue>
                <ButtonSimulationCalc type="button" onClick={() => adicPerct()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="blue"
                gradientDirection="left"
                displayed={displayPercent}
              >
                <div>
                  <h3>{percentualValue}*</h3>
                  <small>Valor recebido por mês</small>
                  <p>
                    *Valor simulado referente a{' '}
                    {percentualValuePercent.toFixed(1)}% do seu saldo projetado,
                    com pagamentos mensais efetuados com base no percentual que
                    escolher no momento da aposentadoria.
                  </p>
                </div>
                <img src={simulationPercentageInfo} alt="Percentual" />
              </ColoredBoxInfo>
            </ReceiveBox>
            <Line />
            {vlrRendaVitalicia === 0 ? (
              <></>
            ) : (
              <ReceiveBox>
                <ButtonSelectBox
                  type="button"
                  onClick={() => toggleSelectedReceive('RV')}
                  selected={selectedReceive === 'RV'}
                >
                  <img src={simulationSelectTimeIcon} alt="Percentual" />
                  <span>Renda Vitalícia</span>
                  <FiCheckCircle />
                </ButtonSelectBox>
                <ColoredBoxInfo
                  size="large"
                  color="orange"
                  gradientDirection="left"
                  displayed={displayVitalicio}
                >
                  <div>
                    <h3>R$ {ValorMascarar(vlrRendaVitalicia.toString())}*</h3>
                    <small>Valor recebido por mês</small>
                    <p>
                      *Valor calculado de acordo com a reserva matemática na
                      data prevista para a aposentadoria.
                    </p>
                  </div>
                  <img src={simulationYearsInfo} alt="Percentual" />
                </ColoredBoxInfo>
              </ReceiveBox>
            )}
            <InputHidden name="tipo" type="hidden" value={selectedReceive} />
            <small className="comment">
              Selecione uma das opções acima para simular o valor que você
              receberá mensalmente ao se aposentar
            </small>
          </Content>
        </Form>

        <Button
          type="button"
          fontSize="normal"
          color="blue"
          width="large"
          onClick={handleSubmit}
        >
          <FiCheck size={40} />É isso que eu quero!
        </Button>

        <BtnVoltar type="button" onClick={() => history.push('/')}>
          &lt; Quero alterar os valores e simular novamente
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Simulation
