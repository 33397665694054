/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import moment from 'moment'
import * as Yup from 'yup'
import {
  FiArrowRightCircle,
  FiClock,
  FiDollarSign,
  FiMinusCircle,
  FiPlusCircle,
  FiUser,
} from 'react-icons/fi'
import { BiCake } from 'react-icons/bi'
import { Collapse } from '@material-ui/core'
import { MdSecurity } from 'react-icons/md'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import { ValorMascarar } from '../../utils/masks'
import { formatValue } from '../../utils/formatValues'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import {
  UserDetails,
  UserData,
  Participant,
  ParticipantDetails,
} from '../../utils/interfaces'
import {
  Container,
  Content,
  Line,
  InfoContent,
  PercentualBox,
  ButtonController,
} from './styles'
import calculaIdade from '../../utils/calculaIdade'
import InputSelect from '../../components/InputSelect'
import validaCPF from '../../utils/validaCPF'

const BemVindo: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetailsConje] = usePersistedState<ParticipantDetails>(
    'userDetails',
    {} as ParticipantDetails,
  )
  const [idadeApos, setIdadeApos] = useState(userDetails.age)
  const [salarioValue, setSalarioValue] = useState(userDetails.salario)
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [pctCtbPatroc, setPctCtbPatroc] = useState(
    userDetails.pctContribuicaoPatrocinadora > 0
      ? userDetails.pctContribuicaoPatrocinadora
      : 0,
  )
  const [pctCtbBas, setPctCtbBas] = useState(
    userDetails.pctContribuicaoBasica > 3
      ? userDetails.pctContribuicaoBasica
      : 3,
  )

  const [estadoCivil, setEstadoCivil] = useState({
    label: userData.dcrMaritalStatus,
    value: userData.maritalStatus,
  })

  const [invalid, setInvalid] = useState({
    label: userData.dcrInvalid,
    value: userData.invalid,
  })

  const [sex, setSex] = useState({
    label: userData.dcrSex,
    value: userData.sex,
  })

  const [sexConj, setSexConj] = useState({
    label: userData.dcrSexMarital,
    value: userData.sexMarital,
  })

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [thisParticipantData] = useState<UserData>({} as UserData)
  const [thisParticipantDetails] = useState<ParticipantDetails>(
    {} as ParticipantDetails,
  )

  useEffect(() => {
    if (salarioValue !== 0 && salarioValue !== undefined) {
      if (pctCtbBas >= 2 && pctCtbBas <= 6) {
        setVlrCtbPatrocinadora(salarioValue * (pctCtbBas / 100))
        setPctCtbPatroc(pctCtbBas)
      } else if (pctCtbBas > 6) {
        setVlrCtbPatrocinadora(salarioValue * 0.06)
        setPctCtbPatroc(6)
      }
    }
  }, [
    salarioValue,
    vlrCtbPatrocinadora,
    vlrCtbBasica,
    setVlrCtbBasica,
    setVlrCtbPatrocinadora,
    pctCtbBas,
  ])

  const handleMudaContrib = useCallback((pct, sal) => {
    const baseCalculo = sal
    let valueCtb = 0
    valueCtb = baseCalculo * (pct / 100)
    if (pct >= 3) {
      setPctCtbBas(parseFloat(pct.toFixed(1)))
      setVlrCtbBasica(isNaN(valueCtb) ? 0 : valueCtb)
    } else {
      // eslint-disable-next-line no-alert
      alert(`Percentual inválido, escolha um valor entre 3 e 100`)
      setPctCtbBas(3)
      valueCtb = baseCalculo * (3 / 100)
      setVlrCtbBasica(isNaN(valueCtb) ? 0 : valueCtb)
    }
  }, [])

  const mudarSalario = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      if (isNaN(v)) {
        setSalarioValue(salarioValue)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setSalarioValue(f)
        handleMudaContrib(pctCtbBas, f)
      }
    },
    [handleMudaContrib, pctCtbBas, salarioValue],
  )

  const handleSubmit = useCallback(
    async data => {
      try {
        const bday = data.birthdate.split('/').reverse().join('-')
        const bdayMarital = data.birthdateMarital.split('/').reverse().join('-')
        const idadeAtual = calculaIdade(bday)
        const idadeAtualConje = calculaIdade(bdayMarital)
        const anosContrib2 = idadeApos - idadeAtual

        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          birthdate: Yup.string()
            .required('Campo obrigatório')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () => moment() > moment(bday) || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () => moment(bday).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () => idadeAtual <= 115 || data.birthdate === '',
            ),
          invalid: Yup.string().required('Campo obrigatório'),
          sex: Yup.string().required('Campo obrigatório'),
          maritalStatus: Yup.string().required('Campo obrigatório'),
          nameMarital: Yup.string().when('maritalStatus', {
            is: () => estadoCivil.value === 'C' || estadoCivil.value === 'U',
            then: Yup.string()
              .required('Campo obrigatório')
              .matches(/\s/g, 'Digite o nome completo')
              .min(3, 'Digite o nome completo'),
          }),
          birthdateMarital: Yup.string().when('maritalStatus', {
            is: () => estadoCivil.value === 'C' || estadoCivil.value === 'U',
            then: Yup.string()
              .required('Campo obrigatório')
              .test(
                '',
                'A data de nascimento não pode ser maior que hoje.',
                () =>
                  moment() > moment(bdayMarital) ||
                  data.birthdateMarital === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  moment(bdayMarital).isValid() || data.birthdateMarital === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () => idadeAtualConje <= 115 || data.birthdateMarital === '',
              ),
          }),
          sexMarital: Yup.string().when('maritalStatus', {
            is: () => estadoCivil.value === 'C' || estadoCivil.value === 'U',
            then: Yup.string().required('Campo obrigatório'),
          }),
          cpfMarital: Yup.string().when('maritalStatus', {
            is: () => estadoCivil.value === 'C' || estadoCivil.value === 'U',
            then: Yup.string()
              .required('Digite o CPF do cônjuge')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfMarital.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfMarital === '',
              ),
          }),
          age: Yup.number()
            .typeError(
              'Digite a idade desejada para a aposentadoria para realizar a simulação',
            )
            .required(
              'Digite a idade desejada para a aposentadoria para realizar a simulação',
            )
            .min(
              anosContrib2 >= 20 ? 60 : idadeAtual + 20,
              `Idade inválida. Por favor, escolha uma idade igual ou superior a ${
                anosContrib2 >= 20 ? '60' : idadeAtual + 20
              } anos`,
            )
            .max(115, 'Idade inválida.'),
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserData({
          ...userData,
          name: data.name,
          birthdate: bday,
          invalid: invalid.value,
          dcrInvalid: invalid.label,
          sex: sex.value,
          dcrSex: sex.label,
          maritalStatus: estadoCivil.value,
          dcrMaritalStatus: estadoCivil.label,
          nameMarital:
            estadoCivil.value === 'C' || estadoCivil.value === 'U'
              ? data.nameMarital
              : '',
          birthdateMarital:
            estadoCivil.value === 'C' || estadoCivil.value === 'U'
              ? bdayMarital
              : '',
          sexMarital:
            estadoCivil.value === 'C' || estadoCivil.value === 'U'
              ? sexConj.value
              : '',
          dcrSexMarital:
            estadoCivil.value === 'C' || estadoCivil.value === 'U'
              ? sexConj.label
              : '',
          cpfMarital:
            estadoCivil.value === 'C' || estadoCivil.value === 'U'
              ? data.cpfMarital
              : '',
        })

        setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasica: vlrCtbBasica,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora,
          pctContribuicaoBasica: pctCtbBas,
          years: anosContrib2,
          age: idadeApos,
          pctContribuicaoPatrocinadora: pctCtbPatroc,
        })

        if (estadoCivil.value === 'C' || estadoCivil.value === 'U') {
          setParticipants([
            {
              ...participants,
              data: {
                ...thisParticipantData,
                name: data.nameMarital,
                cpf: data.cpfMarital,
                birthdate: data.birthdateMarital === '' ? '' : bdayMarital,
              },
              details: {
                ...thisParticipantDetails,
                tipoBen: '2',
                grauParentesco: estadoCivil.value === 'C' ? '1' : '2',
                dcrGrauParentesco:
                  estadoCivil.value === 'C' ? 'Cônjuge' : 'Companheiro(a)',
                proporcao: 0,
              },
            },
          ])
        } else {
          setParticipants([])
        }

        history.push('/simulation')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      estadoCivil,
      history,
      idadeApos,
      invalid,
      participants,
      pctCtbBas,
      pctCtbPatroc,
      salarioValue,
      setParticipants,
      setUserData,
      setUserDetails,
      sex,
      sexConj,
      thisParticipantData,
      thisParticipantDetails,
      userData,
      userDetails,
      vlrCtbBasica,
      vlrCtbPatrocinadora,
    ],
  )

  const handleChangeCtb = useCallback(pct => {
    setPctCtbBas(pct)
  }, [])

  const minusContrib = useCallback(() => {
    handleMudaContrib(pctCtbBas - 0.1, salarioValue)
  }, [handleMudaContrib, salarioValue, pctCtbBas])

  const plusContrib = useCallback(() => {
    handleMudaContrib(pctCtbBas + 0.1, salarioValue)
  }, [handleMudaContrib, salarioValue, pctCtbBas])

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const handleChangeEstadoCivil = useCallback(e => {
    const t = e
    setEstadoCivil(t)
  }, [])

  const handleChangeInvalidez = useCallback(e => {
    const t = e
    setInvalid(t)
  }, [])

  const handleChangeSex = useCallback(e => {
    const t = e
    setSex(t)
  }, [])

  const handleChangeSexConj = useCallback(e => {
    const t = e
    setSexConj(t)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: userData.name,
            age: userDetails.age,
            salario:
              userDetails.salario === undefined
                ? ''
                : ValorMascarar(userDetails.salario.toFixed(2).toString()),
            birthdate:
              userData.birthdate === undefined
                ? ''
                : userData.birthdate.toString().split('-').reverse().join('/'),
            nameMarital: userData.nameMarital,
            birthdateMarital:
              userData.birthdateMarital === undefined
                ? ''
                : userData.birthdateMarital
                    .toString()
                    .split('-')
                    .reverse()
                    .join('/'),
            cpfMarital: userData.cpfMarital,
          }}
        >
          <Content>
            <strong>Simulação de Contribuição</strong>
            <p>
              Será descontada mensalmente de sua folha de pagamento. A empresa
              também fará a contribuição no mesmo valor.
              <br /> Para isso, defina a porcentagem de desconto sobre o seu
              salário e simule sua contribuição mensal.
            </p>
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              icon={BiCake}
              name="birthdate"
              placeholder="Data de nascimento"
              mask="date"
            />
            <InputSelect
              name="invalid"
              value={invalid}
              options={[
                { label: 'Sim', value: 'S' },
                { label: 'Não', value: 'N' },
              ]}
              placeholder="Pessoa inválida?"
              onChange={e => handleChangeInvalidez(e)}
            />

            <InputSelect
              name="sex"
              value={sex}
              options={[
                { label: 'Feminino', value: 'F' },
                { label: 'Masculino', value: 'M' },
              ]}
              placeholder="Sexo"
              onChange={e => handleChangeSex(e)}
            />

            <InputSelect
              name="maritalStatus"
              value={estadoCivil}
              options={[
                { label: 'Casado(a)', value: 'C' },
                { label: 'Solteiro(a)', value: 'S' },
                { label: 'Divorciado(a)', value: 'D' },
                { label: 'Separado(a)', value: 'P' },
                { label: 'União estável', value: 'U' },
                { label: 'Viúvo(a)', value: 'V' },
                { label: 'Outros', value: 'O' },
              ]}
              placeholder="Estado civil"
              onChange={e => handleChangeEstadoCivil(e)}
            />
            <Collapse
              in={estadoCivil.value === 'C' || estadoCivil.value === 'U'}
            >
              <Input
                name="nameMarital"
                placeholder="Nome completo do cônjuge"
                icon={FiUser}
              />
              <Input
                icon={BiCake}
                name="birthdateMarital"
                placeholder="Data de nascimento do cônjuge"
                mask="date"
              />
              <Input
                placeholder="CPF do cônjuge"
                name="cpfMarital"
                icon={MdSecurity}
                type="tel"
                mask="cpf"
              />
              <InputSelect
                name="sexMarital"
                value={sexConj}
                options={[
                  { label: 'Feminino', value: 'F' },
                  { label: 'Masculino', value: 'M' },
                ]}
                placeholder="Sexo do cônjuge"
                onChange={e => handleChangeSexConj(e)}
              />
            </Collapse>

            <Input
              icon={FiClock}
              type="number"
              name="age"
              value={idadeApos}
              min={60}
              sufix="anos"
              placeholder="Qual a idade desejada para a aposentadoria?"
              onChange={e => setIdadeApos(parseInt(e.target.value, 10))}
            />
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário (R$)?"
              onChange={e => mudarSalario(e.target.value)}
            />

            <Line />
            <h4>Contribuição</h4>
            <PercentualBox>
              <p>Com quantos % você quer contribuir?</p>
              <div>
                <ButtonController
                  type="button"
                  disabled={pctCtbBas <= 3}
                  isSelected={pctCtbBas > 3}
                  onClick={() => minusContrib()}
                >
                  <FiMinusCircle />
                </ButtonController>
                <input
                  value={pctCtbBas}
                  type="number"
                  min={3}
                  max={100}
                  step={0.01}
                  onChange={e => {
                    handleChangeCtb(e.target.valueAsNumber)
                  }}
                  onBlur={e => {
                    handleMudaContrib(e.target.valueAsNumber, salarioValue)
                  }}
                />
                %
                <ButtonController
                  type="button"
                  disabled={pctCtbBas >= 100}
                  isSelected={pctCtbBas < 100}
                  onClick={() => plusContrib()}
                >
                  <FiPlusCircle />
                </ButtonController>
              </div>
            </PercentualBox>
            <InfoContent>
              <div>
                <p>Valor da contribuição do participante: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <p>Valor da contribuição da patrocinadora: </p>
                <strong>{formatValue(vlrCtbPatrocinadora)}</strong>
              </div>
            </InfoContent>
            <Line />
          </Content>
        </Form>

        <Button color="blue" onClick={handleClick} width="large">
          Simular valores
          <FiArrowRightCircle size={45} />
        </Button>
      </Container>
    </>
  )
}

export default BemVindo
