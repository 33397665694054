import React from 'react'

import Header from '../../components/Header'

import Img11 from '../../assets/ilustracao_tela11.png'

import { Container, Content } from './styles'

const End: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de aquisição do Plano SarahPrev foi salvo com
            sucesso!
          </strong>

          <img src={Img11} alt="img11" />

          <p>
            Agora é com a gente. <br />
            Vamos avaliar seus dados e em breve você receberá a confirmação da
            adesão ao Plano SarahPrev por e-mail!
          </p>

          {/* <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="https://SarahPrev.com.br/?">SarahPrev.com.br</a>
          </div> */}
        </Content>
      </Container>
    </>
  )
}

export default End
